import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getResources } from '../../../../../utils/api';

const SkynixTeam = () => {
  const { t } = useTranslation('common');
  const [skynixTeam, setSkynixTeam] = useState([]);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getResources(authUser.accessToken).then((res) => {
      setSkynixTeam(res.data.data.resources);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody className="skynix-team">
              <h2 className="text-center">Our Team</h2>
              <div className="d-flex flex-wrap p-3">
                {skynixTeam && skynixTeam.length > 0 ? (
                  skynixTeam.map((s, i) => {
                    return (
                      <div className="wrapper-content d-flex" key={`${s.first_name}_${i}`}>
                        <div
                          className={`circle ${
                            s.is_available ? 'green' : 'red'
                          }`}
                        />
                        <div className="name">
                          {s.first_name} {s.last_name}
                        </div>
                        <div className="skills">
                          Skills: {s.skills ? s.skills : 'No skills'}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>{t('empty_states.default')}</p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SkynixTeam;
