export const columns = [
  {
    Header: '#',
    accessor: 'id',
  },
  {
    Header: 'Invoice ID',
    accessor: 'invoice_id',
  },
  {
    Header: 'Customer',
    accessor: 'customer.name'
  },
  {
    Header: 'Project',
    accessor: 'project_name'
  },
  {
    Header: 'Subtotal',
    accessor: 'subtotal',
  },
  {
    Header: 'Discount',
    accessor: 'discount',
  },
  {
    Header: 'Total',
    accessor: 'total',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
  },
  {
    Header: 'Created Date',
    accessor: 'created_date',
  },
  {
    Header: 'Send Date',
    accessor: 'start_date',
  },
  {
    Header: 'Paid Date',
    accessor: 'end_date',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
    disableSortBy: true,
  },
];