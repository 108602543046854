import { getEnvironments, postAddNewEnvironments, deleteEnvironments, updateEnvironments } from '../../utils/api';

import { displayNotification } from '../../shared/components/Notification';

export const GET_ENVIRONMENTS_FETCHING = 'GET_ENVIRONMENTS_FETCHING';
export const GET_ENVIRONMENTS_FAIL = 'GET_ENVIRONMENTS_FAIL';
export const GET_ENVIRONMENTS_SUCCESS = 'GET_ENVIRONMENTS_SUCCESS';

export const POST_ENVIRONMENTS_FETCHING = 'POST_ENVIRONMENTS_FETCHING';
export const POST_ENVIRONMENTS_FAIL = 'POST_ENVIRONMENTS_FAIL';

export const getEnvironmentsFetchingAction = (project_id, accessToken, query) => {
  return (dispatch) => {
    dispatch({ type: GET_ENVIRONMENTS_FETCHING });

    getEnvironments(project_id, accessToken, query)
      .then(({ data }) => {
        if (data.success) {
          dispatch(getEnvironmentsSuccess(data.data));
          dispatch(getEnvironmentsFail([]));
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(getEnvironmentsFail(data.errors));
          } else {
            dispatch(getEnvironmentsFail([data.errors]));
          }
        }
      })
      .catch(e => {
        if (Array.isArray(e)) {
          dispatch(getEnvironmentsFail(e));
        } else {
          dispatch(getEnvironmentsFail([e]));
        }
      });
  };
};

export const getEnvironmentsFail = (errors) => {
  return { type: GET_ENVIRONMENTS_FAIL, payload: { errors } };
};

export const getEnvironmentsSuccess = (data) => {
  return { type: GET_ENVIRONMENTS_SUCCESS, payload: data };
};

export const postEnvironmentsFetchingAction = (project_id, branch, roles, successCallback) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: POST_ENVIRONMENTS_FETCHING });

    postAddNewEnvironments(project_id, branch, roles, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          successCallback();
        } else {
          dispatch(postEnvironmentsFail(data.errors));
        }
      });
  };
};

export const postEnvironmentsFail = (errors) => {
  return { type: POST_ENVIRONMENTS_FAIL, payload: { errors } };
};


export const deleteEnvironmentAction = (project_id, env_id, toggle) => {
  return (dispatch, getState) => {
    const state = getState();

    deleteEnvironments(project_id, env_id, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          toggle();
          displayNotification('Deleted', 'success');
        } else {
          if (Array.isArray(data.errors)) {
            data.errors.forEach((error) => {
              displayNotification(error.message, 'danger');
            });
          } else {
            displayNotification(data.errors.message, 'danger');
          }
        }
      })
      .catch((e) => {
        displayNotification(e.message, 'danger');
      });
  };
};

export const editEnvironmentAction = (project_id, env_id, branch, roles, successCallback) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: POST_ENVIRONMENTS_FETCHING });

    updateEnvironments(project_id, env_id, branch, roles, state.auth.user.accessToken)
      .then(({ data }) => {
        if (data.success) {
          successCallback();
        } else {
          if (Array.isArray(data.errors)) {
            dispatch(postEnvironmentsFail(data.errors));
          } else {
            dispatch(postEnvironmentsFail([data.errors]));
          }
        }
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          dispatch(postEnvironmentsFail(e.errors));
        } else {
          dispatch(postEnvironmentsFail([e]));
        }
      });
  };
};