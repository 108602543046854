import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import DownloadOutlineIcon from 'mdi-react/DownloadOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from './components/DataReactTableColumns/index';
import { getInvoicesFetchingAction } from '../../../redux/actions/invoicesActions';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import { deleteInvoices, getDownloadPDFInvoices, putPaidInvoices, patchInvoiceWithdraw } from '../../../utils/api';
import ReactTooltip from 'react-tooltip';
import PencilIcon from 'mdi-react/PencilIcon';
import Roles from '../../../config/roles';
import _ from "lodash";
import { parse, stringify } from 'query-string';


const Invoices = () => {
  const { t } = useTranslation('common');
  const { invoices, errors, totalRecords } = useSelector(state => state.invoices);
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [query, setQuery] = useState({ start: 0, limit: 10, search_query: '' });
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [failedDeleteModal, setFailedDeleteModal] = useState(false);
  const [successDeletedModal, setSuccessDeletedModal] = useState(false);
  const [confirmDownloadModal, setConfirmDownloadModal] = useState(false);
  const [failedDownloadModal, setFailedDownloadModal] = useState(false);
  const [messageDownloadError, setMessageDownloadError] = useState('');
  const [downloadId, setDownloadId] = useState(null);
  const [paidId, setPaidId] = useState(null);
  const [paidConfirmModal, setPaidConfirmModal] = useState(false);
  const [paidSuccessModal, setPaidSuccessModal] = useState(false);
  const [paidFailModal, setPaidFailModal] = useState(false);
  const [messagePaidError, setMessagePaidError] = useState('');
  const authUser = useSelector(state => state.auth.user);
  const [distributedId, setDistributedId] = useState(null);
  const [distributedConfirmModal, setDistributedConfirmModal] = useState(false);
  const [distributedSuccessModal, setDistributedSuccessModal] = useState(false);
  const [distributedFailModal, setDistributedFailModal] = useState(false);
  const [messageDistributedError, setMessageDistributedError] = useState('');


  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({ start: currentPage, limit: pageLimit }, { arrayFormat: 'comma' })}`);
    dispatch(getInvoicesFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, query, dispatch, refreshCounter, navigate, location.pathname]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleDelete = () => {
    deleteInvoices(deleteId, authUser.accessToken).then(res => {
      if (res.data.success) {
        setSuccessDeletedModal(!successDeletedModal);
      } else {
        setMessageError(res.data.errors[0].message);
        setFailedDeleteModal(!failedDeleteModal);
      }
    })
  }

  const handleDownload = () => {
    getDownloadPDFInvoices(downloadId, authUser.accessToken).then(res => {
      if (!res.data.success) {
        setMessageDownloadError(res.data.errors[0].message);
        setFailedDownloadModal(!failedDownloadModal);
      } else {
        const fileURL = 'data:application/octet-stream;base64,' + res.data.data.pdf;
        let a = document.createElement('a');
        a.href = fileURL;
        a.download = `${res.data.data.name}`;
        a.click();
      }
    })
  }

  const handlePaid = () => {
    putPaidInvoices(paidId, authUser.accessToken).then(res => {
      if (!res.data.success) {
        setMessagePaidError(res.data.errors[0].message);
        setPaidFailModal(!paidFailModal);
      } else {
        setPaidSuccessModal(!paidSuccessModal);
      }
    })
  }

  const handleDistributed = () => {
    patchInvoiceWithdraw(distributedId, authUser.accessToken)
      .then(({ data }) => {
        if (!data.success) {
          setMessageDistributedError(data.errors[0].message);
          setDistributedFailModal(!distributedFailModal);
        } else {
          setDistributedSuccessModal(!distributedSuccessModal);
        }
      })
      .catch((e) => {
        setMessageDistributedError(e?.message);
        setDistributedFailModal(!distributedFailModal);
      });
  };

  const toggle = () => {
    setConfirmModal(!confirmModal);
  };

  const getSearchValue = _.debounce((value) => {
    setQuery({ ...query, search_query: value });
  }, 600)

  return (
    <Container>
      <Row>
        <Col className="col-12 d-flex justify-content-end">
          <button className="btn btn-success" onClick={() => navigate("/dashboard/invoices/add")}>{t('invoices.add_button')}</button>
        </Col>
        <Col className="col-12">
          <DataReactTable
            tableName={"Invoices"}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit
            }}
            sortTable={true}
            search={true}
            searchValue={getSearchValue}
            emptyStateMessage={t('empty_states.invoices')}
            reactTableData={{
              tableHeaderData: columns, tableRowsData: invoices?.map((c) => {
                return {
                  ...c,
                  id: `${c.id}`,
                  invoice_id: `${Number(c.invoice_id)}`,
                  subtotal: `${isNaN(Number(c.subtotal)) ? c.subtotal : Number(c.subtotal).toFixed(2)}`,
                  discount: `${isNaN(Number(c.discount)) ? c.discount : Number(c.discount).toFixed(2)}`,
                  total: `${isNaN(Number(c.total)) ? c.total : Number(c.total).toFixed(2)}`,
                  currency: `${c.currency}${c.currency !== 'USD' ? "(" + c.exchange_rate + ")" : ""}`,
                  action: (
                    <>
                      {
                        authUser.role === Roles.ADMIN && c.status === "NEW" ?
                          (
                            <>
                              <ReactTooltip id="editIcon" type="dark">
                                <span style={{ color: 'white' }}>
                                  {t("reactTooltips.edit")}
                                </span>
                              </ReactTooltip>
                              <button
                                data-tip
                                data-for="editIcon"
                                style={{ border: 0, background: 'transparent', padding: '5px 0 5px 5px' }}>
                                <PencilIcon
                                  onClick={() => {
                                    navigate(`/dashboard/invoices/${c.id}/edit`)
                                  }}
                                  color="#b1c3c8"
                                  size={18}
                                />
                              </button>
                            </>
                          ) : null
                      }

                      <ReactTooltip id="loadIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          {t("reactTooltips.downloadPDF")}
                        </span>
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="loadIcon"
                        style={{ border: 0, background: 'transparent', padding: '5px 0 5px 5px' }}>
                        <DownloadOutlineIcon
                          onClick={() => {
                            setDownloadId(c.id)
                            setConfirmDownloadModal(true)
                          }
                          }
                          size={18}
                          color="#b1c3c8" />
                      </button>

                      <ReactTooltip id="deleteIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          {t("reactTooltips.delete")}
                        </span>
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="deleteIcon"
                        style={{ border: 0, background: 'transparent', padding: '5px 0 5px 5px' }}>
                        <DeleteIcon
                          onClick={() => {
                            setDeleteId(c.id)
                            setConfirmModal(true)
                          }}
                          size={18}
                          color="#b1c3c8" />
                      </button>
                      {
                        c.status === "NEW" ? (
                          <>
                            <ReactTooltip id="paidIcon" type="dark">
                              <span style={{ color: 'white' }}>
                                {t("reactTooltips.paid")}
                              </span>
                            </ReactTooltip>
                            <button
                              data-tip
                              data-for="paidIcon"
                              style={{ border: 0, background: 'transparent', padding: '5px 0 5px 5px' }}>
                              <CurrencyUsdIcon
                                onClick={() => {
                                  setPaidId(c.id)
                                  setPaidConfirmModal(!paidConfirmModal)
                                }}
                                size={18}
                                color="#b1c3c8" />
                            </button>
                          </>
                        ) : null
                      }
                      {
                        c.status === "PAID" && c.is_withdrawn === 0 && authUser.role === Roles.ADMIN && (
                          <>
                            <ReactTooltip id="paidedIcon" type="dark">
                              <span style={{ color: 'white' }}>
                                Mark as Distributed
                              </span>
                            </ReactTooltip>
                            <button
                              data-tip
                              data-for="paidedIcon"
                              style={{ border: 0, background: 'transparent', padding: '5px 0 5px 5px' }}
                            >
                              <CheckboxMarkedCircleIcon
                                size={18}
                                color="#b1c3c8"
                                onClick={() => {
                                  setDistributedId(c.id);
                                  setDistributedConfirmModal(
                                    !distributedConfirmModal,
                                  );
                                }} />
                            </button>
                          </>
                        )
                      }
                    </>
                  )
                }
              })
            }}
          />
          {
            errors ? (<div>{errors}</div>) : null
          }
          <ConfirmModal
            color="danger"
            btn="Default"
            message={`Are you sure you want to delete invoice #${deleteId}?`}
            toggle={toggle}
            modal={confirmModal}
            onNext={handleDelete}
          />
          <CustomModal
            successModal={failedDeleteModal}
            toggleCloseModal={() => setFailedDeleteModal(!failedDeleteModal)}
            textModal={messageError}
            color={"danger"}
          />
          <CustomModal
            successModal={successDeletedModal}
            toggleCloseModal={() => {
              setSuccessDeletedModal(!successDeletedModal);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Invoice №${deleteId} was successfully deleted!`}
            color={"success"}
          />
          <ConfirmModal
            color="primary"
            btn="Default"
            message={`Are you sure you want to download invoice #${downloadId}?`}
            toggle={() => setConfirmDownloadModal(!confirmDownloadModal)}
            modal={confirmDownloadModal}
            onNext={handleDownload}
          />
          <CustomModal
            successModal={failedDownloadModal}
            toggleCloseModal={() => setFailedDownloadModal(!failedDownloadModal)}
            textModal={messageDownloadError}
            color={"danger"}
          />
          <ConfirmModal
            color="primary"
            btn="Default"
            message={`Are you sure you want to mark as paid invoice #${paidId}?`}
            toggle={() => setPaidConfirmModal(!paidConfirmModal)}
            modal={paidConfirmModal}
            onNext={handlePaid}
          />
          <CustomModal
            successModal={paidSuccessModal}
            toggleCloseModal={() => {
              setPaidSuccessModal(!paidSuccessModal);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Invoice №${paidId} was paid successfully!`}
            color={"success"}
          />
          <CustomModal
            successModal={paidFailModal}
            toggleCloseModal={() => setPaidFailModal(!paidFailModal)}
            textModal={messagePaidError}
            color={"danger"}
          />
          <ConfirmModal
            color="primary"
            btn="Default"
            message={`Are you sure you want to mark as Distributed invoice #${distributedId}?`}
            toggle={() => setDistributedConfirmModal(!distributedConfirmModal)}
            modal={distributedConfirmModal}
            onNext={handleDistributed}
          />
          <CustomModal
            successModal={distributedSuccessModal}
            toggleCloseModal={() => {
              setDistributedSuccessModal(!distributedSuccessModal);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Invoice №${distributedId} was distributed successfully!`}
            color={'success'}
          />
          <CustomModal
            successModal={distributedFailModal}
            toggleCloseModal={() =>
              setDistributedFailModal(!distributedFailModal)
            }
            textModal={messageDistributedError}
            color={'danger'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Invoices;